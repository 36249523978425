import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import WorksModal from "../components/works/modal"
import WorksCarousel from "../components/works/carousel"

export default class IndexPage extends React.Component {
  studentTexts = [
    {main: "2022年 第50回記念 書ノ友社展", sub: "特別賞第三席受賞 (一般)", path: "images/works/fullsize/student_10.jpg", index: 9},
    {main: "2022年 第50回記念 書ノ友社展", sub: "特別賞第三席受賞 展示の様子", path: "images/works/fullsize/student_11.jpg", index: 10},
    {main: "2011年 第47回 創玄展 全国学生書道展", sub: "特選", path: "images/works/fullsize/student_01.jpg", index: 0},
    {main: "2014年 第50回記念 創玄展 全国学生書道展", sub: "学年優秀賞", path: "images/works/fullsize/student_02.jpg", index: 1},
    {main: "2010年 第46回 創玄展 全国学生書道展", sub: "学年優秀賞", path: "images/works/fullsize/student_03.jpg", index: 2},
    {main: "2010年 第46回 創玄展 全国学生書道展", sub: "学年優秀賞", path: "images/works/fullsize/student_04.jpg", index: 3},
    {main: "2014年 第50回記念 創玄展 全国学生書道展", sub: "毎日新聞社賞", path: "images/works/fullsize/student_05.jpg", index: 4},
    {main: "2010年 第46回 創玄展 全国学生書道展", sub: "奨励賞", path: "images/works/fullsize/student_06.jpg", index: 5},
    {main: "2013年 生駒市市民文化祭", sub: "出展の様子", path: "images/works/fullsize/student_07.jpg", index: 6},
    {main: "2013年 生駒市市民文化祭", sub: "出展作品", path: "images/works/fullsize/student_08.jpg", index: 7},
    {main: "2020年 生駒市 あいさつタウン・南ネットワーク 「第1回地域の交流・作品展」", sub: "出展の様子", path: "images/works/fullsize/student_09.jpg", index: 8}
  ];

  myTexts = [
    {main: "2008年 第44回 創玄展", sub: "秀作",  path: "images/works/fullsize/my_1.jpg", index: 0},
    {main: "2008年 第44回 創玄展", sub: "秀作",  path: "images/works/fullsize/my_2.jpg", index: 1},
    {main: "2010年 第46回 創玄展", sub: "秀作",  path: "images/works/fullsize/my_3.jpg", index: 2},
    {main: "2010年 第46回 創玄展", sub: "秀作",  path: "images/works/fullsize/my_4.jpg", index: 3},
    {main: "2021年 第49回記念 書ノ友社展", sub: "会友出品",  path: "images/works/fullsize/my_6.jpg", index: 5},
    {main: "2022年 第50回記念 書ノ友社展", sub: "会友出品",  path: "images/works/fullsize/my_5.jpg", index: 4},
  ];

  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handleWorksClick = this.handleWorksClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handleWorksClick(index, flag, e) {
    e.preventDefault();
    this.setModal(true, flag, index);
  }

  setModal(isShown, flag, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
      modelFlag: flag
    });
  }


  render() {  
    var studentItems = Array.prototype.map.call(this.studentTexts, (value, index) =>
      <div className="col-lg-4 col-sm-6">
        <a className="works-box" href={ value.path } onClick={this.handleWorksClick.bind(this, index, "student")}>
          <Img fluid={this.props.data.image_thumbs.edges[value.index].node.childImageSharp.fluid}/>
          <div className="works-box-caption">
            <div className="project-category text-white-50">
              {value.main}
            </div>
            <div className="project-name">
              {value.sub}
            </div>
          </div>
        </a>
      </div>
    );
  
    var myItems = Array.prototype.map.call(this.myTexts, (value, index) =>
      <div className="col-lg-4 col-sm-6">
        <a className="works-box" href={ value.path } onClick={this.handleWorksClick.bind(this, value.index, "my")}>
          <Img fluid={this.props.data.myimage_thumbs.edges[value.index].node.childImageSharp.fluid}/>
          <div className="works-box-caption">
            <div className="project-category text-white-50">
              {value.main}
            </div>
            <div className="project-name">
              {value.sub}
            </div>
          </div>
        </a>
      </div>
    );

    return (
      <Layout>
        <SEO title="Home"/>
        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">学びは未来の希望</h2>
                <hr className="divider light my-4"/>
                <p className="text-white mb-4">園児から80代の方まで幅広い年齢の方がいらっしゃっています。<br />
                  硬筆/ペン/毛筆(かな・漢字・実用書)と幅広くご指導いたします。
                </p>
                <p className="text-white mb-4">
                  競うより個性を大切にしています。<br />
                  静かに自分と向き合う時間を持ちましょう。
                </p>
                <a className="btn btn-light btn-xl js-scroll-trigger" href="#detail"
                   onClick={Scroller.handleAnchorScroll}>教室詳細について</a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="activity">
          <h2 className="text-center mt-0">近年の活動</h2>
          <hr className="divider my-4"/>

          <div className="row justify-content-md-center">          
            <div className="col-lg-3 col-md-6">
              <div className="mt-5 text-center ">
                <h5 className="text-center mt-0">2022年 (令和4年)</h5>
                <strong>第50回記念 書ノ友社展 指導者及び他2名出品</strong>
                <p>特別賞第三席受賞 (一般)</p>
                <p>会場の様子<br />
                  <Img fluid={this.props.data.DSC00251.childImageSharp.fluid}/>
                  <Img fluid={this.props.data.DSC00247.childImageSharp.fluid}/>
                </p>
                <p>書ノ友社 会長 作品<br />
                  <Img fluid={this.props.data.IMG0771.childImageSharp.fluid}/>
                </p>
                <strong>第50回 全国学生誌上展 2名出品</strong><br />
                ※学年別の受賞ではありません
                <p>
                  銀賞 1名 (小学5年生) <br />
                  銅賞 1名 (小学2年生)
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 offset-md-1">
              <div className="mt-5 text-center">
                <h5 className="text-center mt-0">2021年 (令和3年)</h5>
                <strong>第49回記念 書ノ友社展 指導者出品</strong>
                <p>書ノ友社 会長 作品<br />
                  <Img fluid={this.props.data.IMG0773.childImageSharp.fluid}/>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="works">
          <h2 className="text-center mt-0">生徒の作品</h2>
          <hr className="divider my-4"/>
          
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              {studentItems}
            </div>
          </div>
        </section>

        <section id="myworks">
          <section className="page-section bg-primary">
            <h2 className="text-center text-white mt-0">指導者紹介</h2>
            <hr className="divider light my-4"/>

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-8 text-center">
                  <h4 className="text-white">矢野 和歌子</h4>
                  <p className="text-white-50">
                    元 毎日書道会会友<br />
                    元 創玄書道会準会員<br />
                    書ノ友社 会友
                  </p>
                  <Img fluid={this.props.data.profile.childImageSharp.fluid}/><br />
                </div>
              </div>
            </div>
          </section>
          
          <section className="page-section">
            <h3 className="text-center mt-0">指導者作品紹介</h3>
            <hr className="divider my-4"/>

            <div className="container-fluid p-0">
              <div className="row no-gutters">
                {myItems}
              </div>
            </div>
          </section>
        </section>
        
        <section className="page-section" id="detail">
          <div className="container">
            <h2 className="text-center mt-0">稽古場所・稽古日</h2>
            <hr className="divider my-4"/>
            <p className="text-center mb-5">
              指導内容: 硬筆、ペン、毛筆(かな・漢字・実用書)<br />
              体験入会は子供1ヶ月無料
            </p>
            <div className="row">
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-yen-sign text-primary mb-4"></i>
                  <h3 className="h4 mb-2">月謝</h3>
                  <p className="text-muted mb-0">
                    大人: <br />
                    <b>月5000円 (毎週)</b><br />
                    <b>月3500円 (月2回)</b><br />
                    <b>月2000円 (月1回)</b><br />
                    子供: <b>月4000円 (毎週)</b><br /><br />
                    +それぞれ競書本(書ノ友)などで1000円頂戴しています<br /><br />
                    (入会金は1ヶ月分)

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-calendar-alt text-primary mb-4"></i>
                  <h3 className="h4 mb-2">稽古日</h3>
                  <p className="text-muted mb-0">
                    <b>週1回(月曜日)</b>, 月4回<br />
                    14:00～20:00</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-map-marked-alt text-primary mb-4"></i>
                  <h3 className="h4 mb-2">稽古場所</h3>
                  <p className="text-muted mb-0">指導者宅<br />(奈良県生駒市小瀬町720-20)<br />
                    <a className="btn btn-outline-info btn-sm js-scroll-trigger" href="#map"
                      onClick={Scroller.handleAnchorScroll}>マップへ</a></p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-address-book text-primary mb-4"></i>
                  <h3 className="h4 mb-2">連絡先</h3>
                  <p className="text-muted mb-0">
                    下記問い合わせフォームからご連絡ください<br />
                    <a className="btn btn-outline-info btn-sm js-scroll-trigger" href="#contact"
                      onClick={Scroller.handleAnchorScroll}>問い合わせフォームへ</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-dark text-white" id="map">
          <div className="container text-center">
            <h2 className="mb-4">稽古場所</h2>
            
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13125.74218693917!2d135.7083161!3d34.6689554!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf203d8e4903fd454!2z44KP44GL5pu46YGT5pWZ5a6k!5e0!3m2!1sja!2sjp!4v1611477711171!5m2!1sja!2sjp"
              width="100%"
              height="500"
              title="わか書道教室の地図"
              allowFullScreen=""
              aria-hidden="false" />
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">問い合わせ</h2>
                <hr className="divider my-4"/>
                <p className="text-muted mb-5">
                  見学や入会についての問い合わせは下記の電話、もしくは下記Googleフォームよりお願いします。<br />
                  お気軽にお問い合わせください。
                </p>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 ml-auto text-center">
                  <a href="tel:08062075353">
                    <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                    <div>080-6207-5353</div>
                  </a>
                  <br />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-12 text-center">
                <iframe 
                  src="https://docs.google.com/forms/d/e/1FAIpQLSd0W9Ozs4rOdbcsB_KLps2074dhc-HGBO1kK3Us3AdExoCt5A/viewform?embedded=true"
                  width="100%"
                  height="600"
                  title="わか書道教室 問い合わせフォーム"
                  style={{border: 'solid'}}
                  frameBorder="1"
                  marginHeight="0"
                  marginWidth="0">読み込んでいます…</iframe>
              </div>
            </div>
          </div>
        </section>
        <WorksModal show={this.state.modalShow && this.state.modelFlag === "student"} onHide={() => this.setModal(false, 0)}>
          <WorksCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
            texts={this.studentTexts}/>
        </WorksModal>
        
        <WorksModal show={this.state.modalShow && this.state.modelFlag === "my"} onHide={() => this.setModal(false, 0)}>
          <WorksCarousel
            images={this.props.data.myimages.edges}
            current={this.state.modalCurrent}
            texts={this.myTexts}/>
        </WorksModal>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "works/fullsize/student_*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    image_thumbs: allFile(filter: {relativePath: {glob: "works/fullsize/student_*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 650, maxHeight: 350 ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },

    myimages: allFile(filter: {relativePath: {glob: "works/fullsize/my_*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    myimage_thumbs: allFile(filter: {relativePath: {glob: "works/fullsize/my_*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    profile: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    DSC00251: file(relativePath: { eq: "DSC00251.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    DSC00247: file(relativePath: { eq: "DSC00247.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    IMG0771: file(relativePath: { eq: "IMG0771.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    IMG0773: file(relativePath: { eq: "IMG0773.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
